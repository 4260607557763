


























































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: ''
      })
    }
  },

  setup(_, { root }) {

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: v => !!v || `${root.$t('layout.misc.required')}`
    }

    return { rules }
  }
})
